import React, { useState, useEffect, useRef }from 'react';
import { useSelector, useDispatch } from 'react-redux';
import variables from './redux/variables';
import { autocomplete } from './redux/ducks';
import useComponentVisible from './event-handler/event';

import AutoCompleteSearchIcon from 'components/custom-components/search-box-search-icon-1730873258887/index';
import AutoCompleteClearIcon from 'components/custom-components/search-box-clear-icon-1730873233275/index';
import AutoCompleteLoading from 'components/section-components/search-box-loading/index.jsx';

import AutoSuggestion from 'components/feature-components/auto-suggest-autocomplete-app/index.jsx';
import AutoCompleteResultIcon from 'components/section-components/auto-suggest-icon/index.jsx';
import AutoCompleteResultTitle from 'components/section-components/auto-suggest-title/index.jsx';
import AutoCompleteResultMetadata from 'components/section-components/auto-suggest-metadata/index.jsx';
import RecentSearch from 'components/feature-components/auto-suggest-recent-search/index.jsx';
import dataFormater from './function-library/dataFormatter';

const AutocompleteApp = () => {
  const dispatch = useDispatch();
  let autoCompleteResult  = useSelector((state) => { return state.autocomplete;  });
  let viewedResult = undefined;
  if(autoCompleteResult.length !== 0) {
    viewedResult = dataFormater(autoCompleteResult)?.viewedResult;
  }
  /** inputValue reference to DOM input element of autocomplete box */
  const inputValue = useRef(null);
  let { ref, isComponentVisible ,setIsComponentVisible} = useComponentVisible(true);
  /** 
   * showClearIcons state to manage show/hide clear icon 
   * true - Show clear icon
   * false - hide clear icon
   */
  const [showClearIcons, setShowClearIcons] = useState();

   /**
       * usersArray and privateMsgs states to manage results for respective tabs - Qlik Customisation 
    */
  const [usersArray, setUsers] = useState([]);
  const [privateMsgs, setMsgs] = useState([]);
  const [granularity, setGranularity] = useState('');
  const [GranularityValueChange, isGranularityValueChange] = useState(false);

  /** 
   * showAutoCompleteResult state to manage show/hide autocomplete result dropdown
   * true - show autocomplete result dropdown
   * false- hide autocomplete result dropdown
  */
  const [showAutoCompleteResult, setShowAutoCompleteResult] = useState();

  const [prevIdCall, setPrevIdCall] = useState();
  
  /**
   * changeSearchDataOnArrowKey & previousAction to manage 
   * iterate over autocomplete result using ↓ and ↑ keyboard keys
   */
  const [changeSearchDataOnArrowKey, setChangeSearchDataOnArrowKey] = useState(Array(variables.autocompleteCallVariables.resultsPerPage).fill(null).map((_, i) => i));
  const [previousAction, setPreviousAction] = useState('');
  let [openInNewTab, setOpenInNewTab] = useState(null);

  /** To Keep autocomplete result open on scroll , change keepAutoCompleteResultOpenOnScroll to true  */
  variables.keepAutoCompleteResultOpenOnScroll = false;

  /** This Object can be modified conditionally to set Search API  Url 
   *  Blank Url means default Search Urls is getting used 
   */
  variables.userDefinedAutoCompleteSearchUrl = {
    url : '',
    req : {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(variables.autocompleteCallVariables)
    }
  };

  /** Setting up default result per page to 10 */
  variables.autocompleteCallVariables.resultsPerPage = autoCompleteResult && autoCompleteResult.searchClientSettings?Number(autoCompleteResult.searchClientSettings.autoComplete): 10;

  /**
   * searchAppisPresentOnSamePage - To check if Search App and Autocomplete App present on same page
   * if Both apps are present on same page , we can establish communication b/w them using below script in autocomplete.html file
   * https://<- instance name -> /resources/search_clients_custom/<- uid -> /communication.js
   */
  let searchAppisPresentOnSamePage = document.getElementById('search-box-search') ? true : false;

  /**
   * settings.searchBoxPlaceholder - Autocomplete App Search box placeholder ( no language support in standard code)
   * settings.redirectionLink - search page url 
   * settings.instantSearch - When Search App and Autocomplete Apps are present on same page with communication
   * established  , on every key press in autocomplete box ,updating the search query string in search app also along with results
   * without enter key
   *  
   */
  let settings = {
    searchBoxPlaceholder: 'Search the Community',
    bothAppOnSamePage: searchAppisPresentOnSamePage,
    autocompleteSearchbox : document.querySelector('.top-search'),
    instantSearch: searchAppisPresentOnSamePage ? (autoCompleteResult.searchClientSettings?.autoCompleteInstant == 1 ? true : false) : false,
    redirectionLink : window.location.origin + '/t5/seach-the-community/bd-p/qlikSearchUnify#!/search'
  }
  /**Redriection on search Icon click and enter press*/
  // const redirection = (searchString) => {
  //   let searchVariable = searchString ? searchString : variables.autocompleteCallVariables.searchString;
  //   /** 
  //    *  - redirect from autocomplete app to search app 
  //    *  - Set Redirection url from admin panel , it is basically search app url
  //    *  - on Enter press in autocomplete app / search icon , redirection happen to mentioned
  //    *    url along with search Sting
  //    *  - no redirection if search App exist on same page
  //   */
  //   if (!settings.bothAppOnSamePage) {
  //        let aggregations = [];
  //       // granularity checks to set aggregations accordingly
  //       if (document.getElementsByClassName('search-granularity')[0].value.split("|")[1] != 'notes' && document.getElementsByClassName('search-granularity')[0].value.split("|")[1] != 'user') {
  //           if ( document.getElementsByClassName('search-granularity')[0].value.split("|")[1] == "community") {
  //               if (LITHIUM.CommunityJsonObject.CoreNode.shortTitle != "Search the Community")
  //                   //aggregations.push({ "type": "_type", "filter": ["forum", "tkb"] });
  //                   aggregations = [];
  //           }
            
  //           else if (document.getElementsByClassName('search-granularity')[0].value.split("|")[1] == 'tkb') {
  //               aggregations.push({ "type": "_type", "filter": ["tkb1"] });
  //           }
  //           else if ((document.getElementsByClassName('search-granularity')[0].value.split("|")[1]).split("-")[1] == 'board') {
  //               if (LITHIUM.CommunityJsonObject.CoreNode.shortTitle != "Search the Community")
  //                   aggregations.push({ "type": "boardName", "filter": [encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.title)] });
  //               else
  //                   aggregations = [];
  //           }
  //           else if (document.getElementsByClassName('search-granularity')[0].value.split("|")[1] == 'forum-board') {
  //               aggregations.push({ "type": "boardName", "filter": [encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.shortTitle)] });
  //           }
  //           else {
  //               aggregations = [];
  //           }
  //           // let text = value ? value : variables.searchCallVariables.searchString
  //           /** redirect from autocomplete app to search app */
  //           // if (!bothAppOnSamePage) {
  //               window.open(`${settings.redirectionLink}?searchString=${searchVariable}&from=0&sortby=_score&orderBy=desc&pageNo=1&aggregations=${encodeURIComponent(JSON.stringify(aggregations))}`, '_self');
  //               // window.location = `${redirectionLink}?searchString=${encodeURIComponent(searchVariable)}&from=0&sortby=_score&orderBy=desc&pageNo=1&aggregations=${encodeURIComponent(JSON.stringify(aggregations))}&uid=cb43fd48-5178-11eb-8a9b-0242ac120002&resultsPerPage=10&exactPhrase=&withOneOrMore=&withoutTheWords=&pageSize=10&mergeSources=false`;
  //               // window.open(`${redirectionLink}?searchString=${text}&from=0&sortby=_score&orderBy=desc&pageNo=1&aggregations=${encodeURIComponent(JSON.stringify(aggregations))}&uid=cb43fd48-5178-11eb-8a9b-0242ac120002&resultsPerPage=10&exactPhrase=&withOneOrMore=&withoutTheWords=&pageSize=10&mergeSources=false`, '_self');
  //           // }
  //       }
  //       //granularity check for User tab - Qlik Customisation 
  //       else if (document.getElementsByClassName('search-granularity')[0].value.split("|")[1] == 'user') {
  //           window.location = `/t5/qlik-searchunify/bd-p/qlikSearchUnify#!/search?searchString=${searchVariable}&from=0&sortby=_score&orderBy=desc&pageNo=1&aggregations=${encodeURIComponent(JSON.stringify(aggregations))}&uid=cb43fd48-5178-11eb-8a9b-0242ac120002&resultsPerPage=10&exactPhrase=&withOneOrMore=&withoutTheWords=&pageSize=10&mergeSources=false&tab=user`;
  //       }
  //       //granularity check for Private Messages tab - Qlik Customisation 
  //       else if (document.getElementsByClassName('search-granularity')[0].value.split("|")[1] == 'notes') {
  //           window.location = `/t5/qlik-searchunify/bd-p/qlikSearchUnify#!/search?searchString=${searchVariable}&from=0&sortby=_score&orderBy=desc&pageNo=1&aggregations=${encodeURIComponent(JSON.stringify(aggregations))}&uid=cb43fd48-5178-11eb-8a9b-0242ac120002&resultsPerPage=10&exactPhrase=&withOneOrMore=&withoutTheWords=&pageSize=10&mergeSources=false&tab=privateMsg`;

  //       }
  //   //   window.open(`${settings.redirectionLink}?searchString=${searchVariable}`, '_self');     
  //   }else{
  //     document.getElementById('search-box-search').value = document.getElementById('search-box-autocomplete').value;
  //     document.getElementById('hit-me').click();
  //   }
  // };
  
  const redirection = (searchString) => {
    // Use the provided searchString or fallback to the default variable
    const searchVariable = searchString || variables.autocompleteCallVariables.searchString;

    /** 
     * Redirect from autocomplete app to search app with configurations:
     * - URL is set from admin panel.
     * - Redirection happens on Enter press or search icon click.
     * - No redirection if both apps exist on the same page.
    */
    if (!settings.bothAppOnSamePage) {
        const granularityElement = document.getElementsByClassName('search-granularity')[0];
        const granularity = granularityElement ? granularityElement.value.split("|")[1] : null;
        let aggregations = [];

        // Define a helper function for redirection
        const redirect = (tab = '') => {
            const tabParam = tab ? `&tab=${tab}` : '';
            const url = `${settings.redirectionLink}?searchString=${searchVariable}&from=0&sortby=_score&orderBy=desc&pageNo=1&aggregations=${encodeURIComponent(
                JSON.stringify(aggregations)
            )}${tabParam}`;
            window.location = url;
        };

        if (granularity && granularity !== 'notes' && granularity !== 'user') {
            if (granularity === 'community') {
                if (LITHIUM.CommunityJsonObject.CoreNode.shortTitle !== "Search the Community") {
                    aggregations = [];
                }
            } else if (granularity === 'tkb') {
                aggregations.push({ type: "_type", filter: ["tkb1"] });
            } else if (granularity.includes('-board')) {
                const boardName = encodeURIComponent(
                    granularity === 'forum-board'
                        ? LITHIUM.CommunityJsonObject.CoreNode.shortTitle
                        : LITHIUM.CommunityJsonObject.CoreNode.title
                );
                aggregations.push({ type: "boardName", filter: [boardName] });
            }
            redirect();
        } else if (granularity === 'user') {
            redirect('user');
        } else if (granularity === 'notes') {
            redirect('privateMsg');
        }
    } else {
        // If both apps are on the same page, transfer the search string and trigger the search
        const searchBox = document.getElementById('search-box-search');
        const autoCompleteBox = document.getElementById('search-box-autocomplete');
        if (searchBox && autoCompleteBox) {
            searchBox.value = autoCompleteBox.value;
            document.getElementById('hit-me').click();
        }
    }
};
  useEffect(() => {
    document.body.classList.add('su__autocomplete-running');
  });
  /**
   * Initialising search call
   */
  useEffect(() => {
    variables.searchSource = 'autocomplete';
  }, []);
  
  /** it includes data from recent seach , autosuggestiona and hits */
  let [tempData, setTempData] = useState([]);

  useEffect(() => {
    let tempDataInner = [];
    // autoCompleteResult && autoCompleteResult.recentSearchHistory &&
    //   autoCompleteResult.recentSearchHistory.length != 0 && tempDataInner.push(...autoCompleteResult.recentSearchHistory);
    // autoCompleteResult && autoCompleteResult.result && tempDataInner.push(...autoCompleteResult.result.hits);

    if (autoCompleteResult) {
      const { recentSearchHistory, result, searchClientSettings } = autoCompleteResult;

      if (recentSearchHistory?.length) {
          tempDataInner.push(...recentSearchHistory);
      }
      if (result?.hits) {
          tempDataInner.push(...result.hits);
      }
  }

    // variables.autocompleteCallVariables.searchString && tempDataInner.push({ 'title': variables.autocompleteCallVariables.searchString, 'type': 'userTypedData' });
    if (variables?.autocompleteCallVariables?.searchString) {
      tempDataInner.push({ title: variables.autocompleteCallVariables.searchString, type: 'userTypedData' });
  }

    // if (autoCompleteResult && autoCompleteResult.searchClientSettings) {
    //   setChangeSearchDataOnArrowKey(Array(Number(tempDataInner.length - 1)).fill(null).map((_, i) => i));
    // }
    if (autoCompleteResult?.searchClientSettings) {
      const changeSearchData = Array.from({ length: tempDataInner.length }, (_, i) => i);
      setChangeSearchDataOnArrowKey(changeSearchData);
  }
    setTempData(tempDataInner);

    // if(GranularityValueChange){
    //     if( autoCompleteResult){
    //         setTempData(tempDataInner)
    //         /** keep autocomplete result suggestions Show when search query string is empty */
    //         setShowAutoCompleteResult(true);
    //     }else{
    //         /** keep autocomplete result suggestions Show when search query string is empty */
    //         setShowAutoCompleteResult(false);
    //     }
    //     isGranularityValueChange(false)
    // }
    if (GranularityValueChange) {
      setShowAutoCompleteResult(!!autoCompleteResult);
      isGranularityValueChange(false);
  }
  }, [autoCompleteResult,GranularityValueChange]);
 
     /**
     * Initialising search call on Granularity change drop Down
     */
      useEffect(() => {
        variables.searchSource = 'autocomplete'
          document.getElementsByClassName('search-granularity')[0].addEventListener("change",  dispatchChangeSeachgranularity);
        return () => { 
            document.getElementsByClassName('search-granularity')[0].removeEventListener("change", dispatchChangeSeachgranularity);
        }
      }, []); 

      const dispatchChangeSeachgranularity = (event)=>{
        let value = document.querySelector('.top-search').value || '';
        if(value){
            autoCompleteSearchCall(event, true, value);
            isGranularityValueChange(true);
            setShowAutoCompleteResult(false)

        }
      }

  /**
   * dispatches the search action.
   * event.currentTarget.value - is current user typed search strign
   */
  const dispatchSearchAction = (event) => {
    // setGranularity(document.getElementsByClassName('search-granularity')[0].value.split("|")[1]);
    const granularityElement = document.getElementsByClassName('search-granularity')[0];
    if (granularityElement) {
        setGranularity(granularityElement.value.split("|")[1]);
    }

    /**on first load , intialing the states */
    let tempDataInner = [];
    // autoCompleteResult && autoCompleteResult.recentSearchHistory &&
    // autoCompleteResult.recentSearchHistory.length != 0 && tempDataInner.push(...autoCompleteResult.recentSearchHistory);
    // autoCompleteResult && autoCompleteResult.result && tempDataInner.push(...autoCompleteResult.result.hits);
    if (autoCompleteResult) {
      const { recentSearchHistory, result } = autoCompleteResult;
      if (recentSearchHistory?.length) {
          tempDataInner.push(...recentSearchHistory);
      }
      if (result?.hits) {
          tempDataInner.push(...result.hits);
      }
  }
    // variables.autocompleteCallVariables.searchString && tempDataInner.push({ 'title': variables.autocompleteCallVariables.searchString, 'type': 'userTypedData' });
    if (variables?.autocompleteCallVariables?.searchString) {
      tempDataInner.push({ title: variables.autocompleteCallVariables.searchString, type: 'userTypedData' });
  }

    // if (tempDataInner && tempDataInner.length != 0) {
    //   setChangeSearchDataOnArrowKey(Array(Number(tempDataInner.length - 1)).fill(null).map((_, i) => i));
    // }
    if (tempDataInner.length) {
      setChangeSearchDataOnArrowKey(Array.from({ length: tempDataInner.length }, (_, i) => i));
  }
    setTempData(tempDataInner);
    setPreviousAction('');
    /**  
     * control,esc,shift, spacebar, 4 arrow key ,Alt
     * There should be no search hit on these keys 
     * 
     * No search hit on Ctrl + char or Alt + char 
    */
    const keyCode = event.keyCode;
    const isModifierKey = [17, 27, 16, 32, 37, 38, 39, 40, 18].includes(keyCode);
    const isCtrlV = keyCode === 86 && event.ctrlKey;
    const isDownArrow = keyCode === 40;
    const isUpArrow = keyCode === 38;
    const isEnter = keyCode === 13;

    if (!isModifierKey && !event.ctrlKey && !event.altKey) {
      /** Show clear icon if search query exist  */
      // event.currentTarget.value ? setShowClearIcons(true) : setShowClearIcons(false);
      const searchValue = event.currentTarget.value;
        setShowClearIcons(!!searchValue);
      /** KeyCode 13 is for Enter Key */
      if (settings.instantSearch && (event.keyCode !== 13  || event.type == 'focus')) {
        /**case when instant search is on and both apps are on same page */
        variables.autocompleteCallVariables.searchString = searchValue;
        /** Dispatch search call  */
        variables.searchSource = 'autocomplete';
        dispatch(autocomplete.start(variables.autocompleteCallVariables));
        inputValue.current.value = variables.autocompleteCallVariables.searchString;

      } else {
        setShowAutoCompleteResult(true);
        if (isEnter) {
          if (tempData[changeSearchDataOnArrowKey[0]]?.href && previousAction) {
            inputValue.current.value = tempData[tempData.length - 1].title;
            var hrefValue = tempData[changeSearchDataOnArrowKey[0]].href;
            window.open(hrefValue)
            resultOpenNewTab(tempData[changeSearchDataOnArrowKey[0]], changeSearchDataOnArrowKey[0]);
          }else{
            if(openInNewTab !=null && previousAction != ' ' && previousAction != undefined){
              window.open(openInNewTab, '_blank');
              setOpenInNewTab(null);
            } else{
              /***on Enter press, redirect to search app and hide autocomplete result  */
              settings.redirectionLink && redirection(event.currentTarget.value);
            }
          }
          setShowAutoCompleteResult(false);
        } else {
          if (event.currentTarget.value) {
            /** 
              * setting autoCompleteResult.result state to null before sending new search call
              * So to clear old data in state , avoid flicker impact on autocomplete dropdown result
              * if result is null - loading is added below autocomplete searchbox(means waiting for response)
              */
            autoCompleteResult.result = null;
            /**  
             * event.persist() to save event asynchronously 
             * delay is added  to achieve debouncing so during that time event obj value should not be lost
             * event.currentTarget.value consist of our search text query
            */
            event.persist();
            let hasProp = event.currentTarget.hasOwnProperty('value');
            let value = event.currentTarget.value;
            /**
             * setTimeout of 500 ms before hitting search api
             * if we remove below setTimeout, search api hit will happen on every single keypress 
             * this put significant load of backend resources
             * 
             * So to avoid multiple hits , debounce is achived here
             * when user take pause for atleast 500ms while typing , autoCompleteSearchCall function is called
             */
            clearTimeout(prevIdCall);
            setPrevIdCall(setTimeout(() => { autoCompleteSearchCall(event, hasProp, value); }, 1000));
          }
          else { 
            /** keep autocomplete result suggestions hide when search query string is empty */
            setShowAutoCompleteResult(false);
          }
        }
      }
    } else if (isCtrlV){
        let hasProp = event.currentTarget.hasOwnProperty('value');
        let value = event.currentTarget.value;
        if(event.currentTarget.value.trim() !=''){
            setPrevIdCall(setTimeout(() => { autoCompleteSearchCall(event, hasProp, value); }, 1000));
        }
    } else if (isDownArrow) { 
      /** 40 keycode for down arrow ↓ */
      autoCompleteResult && autoCompleteResult.result?.hits.length && handleUpDownArrowFunctionality('down');
    } else if (isUpArrow) { 
      /** 38 keycode for up arrow ↑ */
      autoCompleteResult && autoCompleteResult.result?.hits.length && handleUpDownArrowFunctionality('up');
    }
  };

  const handleUpDownArrowFunctionality = (action) => {
    let data = changeSearchDataOnArrowKey;
    if (action == 'down' && previousAction) {
      data.push(data.shift());
    } else if (action == 'up' && previousAction) {
      data.unshift(data.pop());
    }
    if(tempData[data[0]]){
      if (tempData[data[0]].type && ['userTypedData', 'recentSearch', 'autoSuggestion'].includes(tempData[data[0]].type)) {
        inputValue.current.value = tempData[data[0]].title;
      } else {
        inputValue.current.value = tempData[data[0]].highlight.TitleToDisplayString[0] || tempData[data[0]].href;
        setOpenInNewTab(tempData[data[0]].href);
      }
    }
   
    /** Move Focus to current highlighted result */
    // tempData[data[0]].type != 'userTypedData' && document.getElementsByClassName('su__suggestions-list')[data[0]].focus();
    if (currentData.type !== 'userTypedData') {
      const suggestionElement = document.getElementsByClassName('su__suggestions-list')[data[0]];
      suggestionElement?.focus();
  }
    /**Move focus back to autocomplete app search box */
    document.getElementById('search-box-autocomplete1').focus();
    setPreviousAction(action);
    setChangeSearchDataOnArrowKey([...data]);
  };

  const autoCompleteSearchCall = (e, hasProp, value) => {
    if (hasProp) {
      variables.usersArray = [];
      let check = 0;
      /** dispatch autocomplete search  */
      variables.autocompleteCallVariables.searchString = value;
      var aggResults = [];
      //fetch request for User granularity  - Qlik Customisation 
      const granularity = document.getElementsByClassName('search-granularity')[0]?.value.split("|")[1];
      // changed the string for sandbox
      const isCommunitySearch = LITHIUM.CommunityJsonObject.CoreNode.shortTitle != "Seach the Community";
    
      if (granularity == 'user') {
          variables.suResults = false;

          fetch(`/t5/community/page.searchformv32.usersearchfield.usersearchfield:autocomplete?t:cp=search/contributions/page&q=${variables.autocompleteCallVariables.searchString}&limit=1000&timestamp=1608705331636&searchContext=user%7Cuser`)
              .then(response => response.json())
              .then(res => {
                  res.map(function (user, $index) {
                      user.type = "users";
                      user.href = user.value.url
                      user.imgsrc = jQuery(user.data[0]).find('.lia-user-avatar-message')[0].src
                      user.index = ($index + 1)
                  }) || [];
                  variables.usersArray = res;
                  setUsers(res);
                  variables.privateMsgs = [];
                  // setState(res);
              })
      }
        //fetch request for Private Messages granularity  - Qlik Customisation 
      else if (granularity == 'notes') {
          variables.suResults = false;
          fetch(`/t5/community/page.searchformv32.notesearchfield.notesearchfield:autocomplete?t:cp=search/contributions/page&q=${variables.autocompleteCallVariables.searchString}&limit=1000&timestamp=1608705331636&searchContext=undefined`)
              .then(response => response.json())
              .then(res => {
                  if (res.length != 0) {
                      res.map(function (notes, $index) {
                          var imgRegex1 = new RegExp("<IMG[^>]*?>", "g");
                          var imgRegex2 = new RegExp("<img[^>]*?>", "g");
                          notes.type = "notes";
                          notes.href = notes.value.url
                          notes.index = ($index + 1)
                      }) || [];
                  }
                  setMsgs(res);
                  variables.privateMsgs = res;
                  variables.usersArray = [];
              })
      }
      else {
          variables.privateMsgs = undefined;
          variables.usersArray = undefined;
          variables.suResults = true;
          if (granularity == "community") {
              if (isCommunitySearch)
                  //aggResults = [{ "type": "_type", "filter": ["forum", "tkb"] }];
                   aggResults = [];

          }
          else if ((granularity).split("-")[1] == 'board') {
              if (isCommunitySearch)
                  aggResults = [{ "type": "boardName", "filter": [encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.title)] }];
              else
                  aggResults = [];
          }
          else if (granularity == 'tkb') {
              aggResults = [{ "type": "_type", "filter": ["tkb1"] }];

          }
          else if (granularity == 'forum-board') {
              aggResults = [{ "type": "boardName", "filter": [encodeURIComponent(LITHIUM.CommunityJsonObject.CoreNode.shortTitle)]}];

          }
          variables.autocompleteCallVariables.aggregations = aggResults || [];
          // dispatches the request for the rest granularities except user and private messages
            if((variables.searchClientType == 11 || variables.searchClientType == 12) && !window.su_utm) {
                const intervalId = setInterval(() => {
                check++;
                if( ((variables.searchClientType == 11 || variables.searchClientType == 12) && window.su_utm) || check > 3 ) {
                    clearInterval(intervalId);
                    performSearchCall();
                }
                }, 60 * 100);
            } else {
                performSearchCall();
            }
      }
    }

    function performSearchCall() {
      dispatch(autocomplete.start(variables.autocompleteCallVariables));
    }
  };

  useEffect(() => {
    inputValue.current.focus();
    /**Set changeSearchDataOnArrowKey based on search results  */
    if (autoCompleteResult && autoCompleteResult.searchClientSettings) {
      setChangeSearchDataOnArrowKey(Array(Number(autoCompleteResult.searchClientSettings.autoComplete)).fill(null).map((_, i) => i));
    }
  }, [autoCompleteResult && autoCompleteResult.searchClientSettings && autoCompleteResult.searchClientSettings?.autoComplete]);

  /**
   *  autocomplete gza function
  */
  const resultOpenNewTab = (item, index) => {
    setIsComponentVisible(false);
    window.analyticsRecordAlready = true;
    gza('search', {
      'searchString': variables.autocompleteCallVariables.searchString,
      'result_count': autoCompleteResult.result.hits.length,
      'page_no': 0,
      'uid': variables.autocompleteCallVariables.uid,
      'filter': [],
      'conversion': [{
        rank: (index + 1),
        url: item['href'],
        subject: item['highlight']['TitleToDisplayString'][0] || '',
        es_id: item['sourceName'] + '/' + item['objName'] + '/' + encodeURIComponent(item['_id']),
        sc_analytics_fields: item['trackAnalytics']
      }]
    });  
  };

  return (
    <div className={`su__app su__wrapper su_autocomplete-block ${viewedResult ? 'su__viewed-results' : ''}`} id="su_autocomplete-block">
      <section className="su__w-100 su__py-4 su__search_section su__bg-blue-grd">
        <div className="su__container">
          <form autoComplete="off" id="searchForm" className="su__search-forms su__m-0" onSubmit={(e) => { e.preventDefault(); }}>
            <div ref={ref} className="su__form-block su__w-100 su__position-relative">
              <div className="su__radius-2 su__d-flex su__position-relative">
                <input ref={inputValue} id="search-box-autocomplete" className="su__input-search su__w-100 su__su__font-14 su__text-black  su__border-none su__radius-2 su__pr-60" type="input" placeholder={settings.searchBoxPlaceholder} aria-label="Search the Community - Start typing, then use the up and down arrows to select an option from the list" onKeyUp={(e) => dispatchSearchAction(e)} />
                <AutoCompleteClearIcon showClearIcons={showClearIcons} setShowAutoCompleteResult={setShowAutoCompleteResult} setShowClearIcons={setShowClearIcons} autocompleteSearchbox={settings.autocompleteSearchbox} />
                <AutoCompleteSearchIcon redirection={redirection} />
              </div>
              <AutoCompleteLoading isResultExist={(autoCompleteResult && autoCompleteResult.result) || usersArray} searchString={inputValue.current?.value} />
              {isComponentVisible && (
                <div >
                    {/* Customized user and private messages components to display the results  - Qlik Customisation */}
                        {usersArray && granularity == 'user' &&
                        <div className="su__autoSuggestion su__w-100 su__bg-white su__sm-shadow su__position-absolute su__zindex-2">
                            <div id="my_div" className="su__suggestions-box">
                                <div className="auto-suggestion">
                                    {usersArray.length != 0 && <div className="recommendedResult">Users</div> }
                                    {usersArray.length != 0 && usersArray.map((item, index) => (
                                        <div className="su__cursor autosuggestions-li">
                                            <img class="su-userImg" src={item.imgsrc}></img>
                                            <div><a href={item.value.url} >{item.result}</a></div>
                                        </div>
                                    ))}
                                    {usersArray.length == 0 &&
                                        <div className="autosuggestions-li"><span className="noUsers">No results found</span></div>
                                    }
                                </div> 
                            </div>
                        </div>
                        }
                        {privateMsgs && granularity == 'notes' &&
                            <div className="su__autoSuggestion su__w-100 su__bg-white su__sm-shadow su__position-absolute su__zindex-2">
                                <div id="my_div" className="su__suggestions-box">
                                    <div className="auto-suggestion">
                                        {privateMsgs.length != 0 && <div className="recommendedResult">Private Messages</div>}
                                         {privateMsgs.length != 0 && privateMsgs.map((item, index) => (
                                            <div className="su__cursor autosuggestions-li">
                                                <div><a href={item.value.url} >{item.result}</a></div>
                                            </div>
                                        ))}
                                        {privateMsgs.length == 0 &&
                                            <div className="autosuggestions-li"><span className="noUsers">No results found</span></div>
                                        }
                                    </div> 
                                 </div> 
                            </div>
                        }
                        {/* Components to display the results except User and Private Message tab  - Qlik Customisation */}
                        {!showAutoCompleteResult  && granularity != 'user' && granularity!= "notes" ? null :
                <AutoSuggestion openInNewTab={openInNewTab}  resultOpenNewTab={resultOpenNewTab} currentSearchString={inputValue.current.value} autoCompleteResult={autoCompleteResult} dataType={tempData[changeSearchDataOnArrowKey[0]] && tempData[changeSearchDataOnArrowKey[0]].type ? 'recentSearch' : 'autosuggest'} component="autocomplete" >
                  <RecentSearch position="aboveAllResult" redirection={redirection}/>
                  <AutoCompleteResultIcon position="icon" />
                  <AutoCompleteResultTitle position="result" />
                  <AutoCompleteResultMetadata position="result" />
                  {/*   send position ->  belowAllResult to set component below all autocomplete results  */}
                  {/*   send position ->  aboveAllResult to set component above all autocomplete results  */}
                </AutoSuggestion>
                }</div>
                )
                }
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default AutocompleteApp;