/* eslint-disable no-undef */
// import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/merge';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/timer';
import variables from './variables';
import { search } from './ducks';
import utilityMethods from './utilities/utility-methods';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { searchUrlReq } from './searchClientTypes';
import searchtrack from '../analytics/search';

/**
 * Make search call to backend on the basis of search client type.
 * @param {} queryPassed
 */
const htmlDecode = function (input) {
  let e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};

const searchEpic = (action$) =>
  action$.pipe(
    ofType(search.start().type),
    mergeMap(async ({ queryPassed }) => {
      queryPassed['getAutoTunedResult'] = true;
      queryPassed['getSimilarSearches'] = true;
      variables.searchCallVariables.searchString = queryPassed.searchString;
      let queryParams = utilityMethods.getQueryStringParams(
        window.location.href.split('?')[1]
      );
      if (variables.controllingVariables.firstTimeLoad && !variables.controllingVariables.processing) {
        queryParams.state = 1;
        utilityMethods.importQueryParamsToVariables();
      }
      if (variables.controllingVariables.firstTimeLoad) {

        for (var i = 0; i < queryPassed.aggregations.length; i++) {
          if (queryPassed.aggregations[i]?.type == 'boardName') {
            //  queryPassed.aggregations[i].filter[0] =encodeURIComponent(queryPassed.aggregations[i]?.filter[0]);

            queryPassed.aggregations[i].filter?.forEach((item, index) => {
              queryPassed.aggregations[i].filter[index] = encodeURIComponent(item);
            })
          }
        }
      }
      variables.controllingVariables.firstTimeLoad = false;
      if (!variables.onpopstate) {
        utilityMethods.updateURLParams();
      }
      variables.onpopstate = false;
      variables.searchCallVariables = JSON.parse(JSON.stringify(variables.searchCallVariables));
      variables.controllingVariables.processing = true;
      let filterAggregations = variables.searchCallVariables.aggregations;
      if(filterAggregations.length) {
        filterAggregations = filterAggregations.filter(function(filter, index) {
          if(filter.filter) {
            return filterAggregations[index];
          } else {
            return false;
          }
        });
      }

      queryPassed.mergeSources = filterAggregations.length == 0 ? true : false;
      queryPassed.versionResults = Boolean(queryPassed.mergeSources);
      queryPassed.suCaseCreate = Boolean(variables.searchCallVariables.suCaseCreate);
      let start = new Date();
      queryPassed.versionResults = true;
      if (queryPassed && queryPassed.aggregations && queryPassed.aggregations.length > 0) {
        queryPassed.aggregations.forEach(val => {
          if (val.type == "_type") {
            variables.allSelected = false;
          }
        })
      }
      let paramsUrlReq = await searchUrlReq(variables.searchClientType, queryPassed);
      try {
        const response = await fetch(paramsUrlReq.url, paramsUrlReq.req);
        variables.controllingVariables.processing = false;
        if (!response.ok) {
          window.location.href = window.location.origin + "/t5/forums/searchpage/tab/message?standard=true&q=" + variables.autocompleteHeaderCallVariables.searchString;
          throw Error(response.statusText);
        }
        const resp = response;
        const results = await resp.json();
        for(let i of results.aggregationsArray){       
          for(let j of i.values){
            if(j.Contentname){
              j.ContentnameFrontend= htmlDecode(j.Contentname);   
            }
          }
        }    
        let sortedversion = []
          for (let index = 0; index < results.result.hits.length; index++) {
            let element = results.result.hits[index];

            if (element.metadata && element.metadata.length)
            element.metadata.sort(function (a, b) {
                if (a.key < b.key) {
                    return -1;
                }
                if (a.key > b.key) {
                    return 1;
                }
                return 0;
            });
            let splitElementVersion = element.version && element.version != '' && element.version.match(/[a-z]+|\d+/ig);
            if(splitElementVersion){
              element.version = splitElementVersion.join(' ');

            }
            const versionNames = new Set();
            let filteredElement;
            let filteredElementNonDate;
            if(element && element.docVersions){

            

                const uniqueDocVersions = element.docVersions.map(docVersion => {
                const uniqueVersions = docVersion.version.filter(version => {
                    if (versionNames.has(version.name)) {
                    return false;
                    }
                    if (version.name == '' || version.name == null) {
                    return false;
                    }
                    let splitVersions = version.name != '' && version.name.match(/[a-z]+|\d+/ig);
                    version.name = splitVersions.join(' ');
                    versionNames.add(version.name);
                    return true;
                });
                docVersion.version = uniqueVersions;
                return docVersion;
                });
                uniqueDocVersions.map(el => {
                filteredElement = el.version.filter(val => (new Date(val.name) instanceof Date && !isNaN(new Date(val.name))));
                filteredElementNonDate = el.version.filter(val => !(new Date(val.name) instanceof Date && !isNaN(new Date(val.name))));
                el.version = filteredElement.sort((a, b) => new Date(b.name) - new Date(a.name));
                if (filteredElementNonDate.length > 0) {
                    el.version.push(...filteredElementNonDate)

                }
                });
                element.docVersions= uniqueDocVersions
            }
          }
        variables.searchResposeTimer = new Date() - start;
        searchtrack(results); /** search analytics tracking */
        if (variables.searchClientType === 2 || variables.searchClientType === '2') {
          if (results.result.hits.length !== 0) {
            for (let i = 0; i < results.result.hits.length; i++) {
              let lastIndex = (results.result.hits.length - 1);
              let checkLithiumResults = results.result.hits.filter(function (item) {
                if (item.boardName !== '') {
                  return true;
                }
              });
              let lastIndexOfLithiumResult = checkLithiumResults.pop();
              /* if (results.result.hits[i].boardName) {
                let getSearchResultsLiveCountsUrl = window.su_community_live_count + '?postId=' + (results.result.hits[i].ID || results.result.hits[i]._id);
                let getSearchResultsLiveCountsReq = {
                  method: 'GET'
                };
                if (results.result.hits[i].Id !== lastIndexOfLithiumResult.Id) {
                  fetch(getSearchResultsLiveCountsUrl, getSearchResultsLiveCountsReq)
                    .then((response) => response.json())
                    .then((data) => {
                      results.result.hits[i].liveCounts = {};
                      results.result.hits[i].liveCounts = data;
                    })
                    .catch((error) => {
                      console.error('Error:', error);
                    });
                } else {
                  return fetch(getSearchResultsLiveCountsUrl, getSearchResultsLiveCountsReq)
                    .then((response) => response.json())
                    .then((data) => {
                      results.result.hits[i].liveCounts = {};
                      results.result.hits[i].liveCounts = data;
                      return search.success(results);
                    })
                    .catch((error) => {
                      console.error('Error:', error);
                    });
                }
              } else { */
                if (i === lastIndex) {
                  return search.success(results);
                }
              // }
            }
          } else {
            return search.success(results);
          }
        } else {
          return search.success(results);
        }
      }
      catch (err) {
          console.log('Error:', err);
          window.location.href = window.location.origin + "/t5/forums/searchpage/tab/message?standard=true&q=" + variables.autocompleteHeaderCallVariables.searchString;
          return search.fail(err);
      }
    })
  );
export default searchEpic;